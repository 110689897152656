


















































































































































































import draggable from 'vuedraggable';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent, groupBy } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import DataLoading from '@/lib/mixins/dataLoading';
import { currentDate } from '@/utils/dates';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TasqItemKanban: () => getComponent('tasqs/TasqItemKanban'),
    draggable,
  },
})
export default class Kanban extends mixins() {

  dataLoading = false


  draggableData: {
    username: string,
    tasqs: TasqJob[],
    new: TasqJob[],
    inProgress: TasqJob[],
	waitingOn: TasqJob[],
    scheduled: TasqJob[],
    resolved: TasqJob[],
  }[] = [];

  get currentDate() {
    return currentDate();
  }

  get tasqs(): TasqJob[] {
    return [...tasqsListModule.tasqList, ...tasqsListModule.completedTasqList];
  }

  get activeTasqsCount() {
    return this.tasqs.filter(i => i.stepOneCompleted && !i.completed).length;
  }

  get completedTasqCount() {
    return this.tasqs.filter(i => i.completed).length;
  }

  get notStartedCount() {
    return this.tasqs.length - this.activeTasqsCount - this.completedTasqCount;
  }

  get tasqsJobTypes() {
    return [...new Set(this.tasqs.map(i => i.overriddenName))];
  }

  get tasqsJobTypesData() {
    return this.tasqsJobTypes.map(
      i => this.tasqs.filter(t => t.overriddenName === i).length
    )
  }

  async created() {
	this.dataLoading = true
    await accountModule.getReassignmentList();
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    await tasqsListModule.getWaitList();
	
    this.draggableData = Object.entries(groupBy(this.tasqs, 'username')).map((i: any) => ({
      username: getNameByEmail(i[0]),
      tasqs: i[1] as TasqJob[],
      new: i[1].filter(i => !i.responseData),
      inProgress: i[1].filter(i => i.responseData && !i.completed),
	    waitingOn: tasqsListModule.waitingTasqList,
      scheduled: i[1].filter(i => i.engineerType === 'scheduled job' || i?.Role === 'Workover'),
      resolved: i[1].filter(i => i.completed),
    }));
	this.dataLoading = false
  }
}
